import { useReactiveVar } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { globalVar } from '../../../../cache';
import { useUserLoginLazyQuery } from '../../../../graphql/generated';
import { AppRoutes, LocalStorageItems } from '../../../../helpers/constants';
import { useProfile } from '../../../../helpers/customHooks';
import { loginSchema } from '../../../../helpers/yupValidatorSchemas';
import checkRedirectPath from '../../../../services/checkRedirectPath';

interface ILoginFormInputs {
  email: string;
  password: string;
}

export const useLoginFormController = () => {
  const { isLoggedIn } = useReactiveVar(globalVar);
  const history = useHistory();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showAPIError, setShowAPIError] = useState(false);
  const [login, { loading, error: userLoginError, data }] = useUserLoginLazyQuery({
    fetchPolicy: 'network-only',
    onError: () => {
      setShowAPIError(true);
    }
  });

  //register form and resolver
  const {
    formState: { errors },
    handleSubmit,
    register
  } = useForm<ILoginFormInputs>({
    resolver: yupResolver(loginSchema),
    mode: 'onBlur'
  });

  const isRedirectPathExists = checkRedirectPath();
  useEffect(() => {
    if (isLoggedIn) {
      if (!isRedirectPathExists) {
        history.push(AppRoutes.Publications);
      } else {
        const profileId = useProfile()._id;
        history.push(`/user/${profileId}`);
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const accessToken = data?.login.accessToken;
    const user = data?.login.user;
    if (user && accessToken) {
      localStorage.setItem(LocalStorageItems.Token, accessToken);
      localStorage.setItem(LocalStorageItems.UserId, user._id);
      globalVar({
        isLoggedIn: !!accessToken && !!user._id,
        profile: {
          _id: user._id,
          roles: user.roles,
          firstName: user.firstName,
          lastName: user.lastName,
          image: user.image
        }
      });
    }
  }, [data]);

  const onFinish: SubmitHandler<ILoginFormInputs> = async (formValues: ILoginFormInputs, e?: BaseSyntheticEvent) => {
    e?.preventDefault();
    await login({
      variables: {
        ...formValues
      }
    }).catch(() => {
      //setShowAPIError(true);
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onLoginFormSubmitHandle = handleSubmit(onFinish);

  const formText = {
    login: {
      title: 'Log In',
      emailLabel: 'Email',
      passwordLabel: 'Password',
      submitButton: 'Login',
      bottomText1: 'Forgot password? ',
      bottomText1Cta: 'Forgot Password?',
      bottomText2: "Don't have an account? ",
      bottomText2Cta: 'Sign Up'
    },
    signUpSuccess: {
      title: 'Registration Successful!',
      subtitle: 'An activation link has been sent to your email.'
    }
  };

  return {
    formErrors: errors,
    handleClickShowPassword,
    loading,
    onLoginFormSubmitHandle,
    register,
    showAPIError,
    showPassword,
    userLoginError,
    formText
  };
};
