import React from 'react';
import { Title2, TypographyStyled } from '../../landingPage/LandingPage.styles';
import SignUpForm from '../../forms/signupForm/SignUpForm';
import { Typography } from '@mui/material';
import { useLandingPageController } from '../../landingPage/LandingPage.controller';
import { CtaRoot, CtaTextWrapper } from '../../GuestSite.styles';

const SignupCta: React.FC = () => {
  const { text } = useLandingPageController();
  return (
    <CtaRoot>
      <CtaTextWrapper>
        <Title2>{text.ctaTitle}</Title2>
        <Typography variant="h4" fontWeight={400} color={'text.secondary'}>
          {text.ctaSubtitle}
        </Typography>
      </CtaTextWrapper>
      <SignUpForm />
    </CtaRoot>
  );
};

export default SignupCta;
