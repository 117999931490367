import React, { FC } from 'react';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import { Remove, Add } from '@material-ui/icons';
import { AccordionDetailsTextStyled, AccordionStyled, IconWrapper } from './FaqList.styles';
import { Title3 } from '../../LandingPage.styles';

type Items = { title: string; text: string; id: number }[];

export const FaqList: FC = () => {
  const items: Items = [
    {
      title: 'Who is Socoders for?',
      text: 'Socoders is for anyone in the tech industry — whether you’re a developer, designer, engineer, or product manager — who wants to grow, learn, and collaborate with others in the field.',
      id: 1
    },
    {
      title: 'Is it free to use?',
      text: "Yes! Socoders is completely free to use. Whether you're looking to collaborate on projects, showcase your work, or network with others in the tech community, you can do so without any cost.",
      id: 2
    },
    {
      title: 'Can I collaborate with others on projects?',
      text: 'Absolutely! Socoders is designed to foster collaboration. You can team up with developers, designers, engineers, and product managers to work on projects, share knowledge, and grow together.',
      id: 3
    },
    {
      title: 'Can I showcase my projects on the platform?',
      text: 'Yes! You can create a portfolio and display your projects to the community. Whether you’re working on an open-source tool, a startup, or a passion project, Socoders provides a space to share your work and get feedback.',
      id: 4
    },
    {
      title: 'How are you different from other tech platforms?',
      text: 'Unlike traditional job boards or social networks, Socoders is focused on real collaboration and growth. Our platform is built for people who want to work together, learn from each other, and create meaningful tech projects.',
      id: 5
    }
  ];

  const [expanded, setExpanded] = React.useState(0);

  const handleChange = (id: React.SetStateAction<number>) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? id : 0);
  };

  return (
    <div>
      {items.map(({ title, text, id }) => (
        <AccordionStyled expanded={expanded === id} key={id} square onChange={handleChange(id)}>
          <AccordionSummary>
            <IconWrapper>{expanded === id ? <Remove /> : <Add />}</IconWrapper>
            <Title3>{title}</Title3>
          </AccordionSummary>

          <AccordionDetails style={{ margin: '0 0 0 3rem' }}>
            <AccordionDetailsTextStyled variant="caption">{text}</AccordionDetailsTextStyled>
          </AccordionDetails>
        </AccordionStyled>
      ))}
    </div>
  );
};
