import React from 'react';
import { LogoRoot, LogoText } from './Logo.styles';
import { ReactComponent as LogoIcon } from '../../../assets/svg/logo.svg';

interface LogoProps {
  isLogoOnly?: boolean;
}
const Logo: React.FC<LogoProps> = ({ isLogoOnly }) => {
  return (
    <LogoRoot>
      <LogoIcon />
      {isLogoOnly || <LogoText variant="h4">SoCoders</LogoText>}
    </LogoRoot>
  );
};

export default Logo;
