import { Stack, styled } from '@mui/material';

export const FooterRoot = styled(Stack)`
  padding: 0 32px 32px 32px;
  width: 100%;
  margin-top: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 0 16px 16px 16px;
  }
`;

export const FooterWrapper = styled(Stack)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 32px 80px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: white;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 24px 16px;
  }
`;
