import React from 'react';
import { Typography } from '@mui/material';
import { SignUpButton } from '../../landingPage/components/signUpButton/SignUpButton';
import Logo from '../../../sharedComponents/logo/Logo';
import { TopNavigation, ButtonsBox } from './LandingPageHeader.styles';
import { ButtonLink, LoginButtonLink } from '../../GuestSite.styles';
import { useLandingPageHeaderController } from './LandingPageHeader.controller';

const LandingPageHeader: React.FC = () => {
  const { isPathLogin, isPathSignup } = useLandingPageHeaderController();

  return (
    <TopNavigation>
      <ButtonLink to="/">
        <Logo />
      </ButtonLink>
      <ButtonsBox>
        {!isPathLogin && (
          <LoginButtonLink to="/login" hideSignUp={isPathSignup} hideLogin={isPathLogin}>
            <Typography variant="subtitle1">Log in</Typography>
          </LoginButtonLink>
        )}
        {!isPathSignup && <SignUpButton />}
      </ButtonsBox>
    </TopNavigation>
  );
};

export default LandingPageHeader;
