import { Button, CircularProgress, InputLabel } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useUserPasswordResetRequestMutation } from '../../../../graphql/generated';
import { Validate } from '../../../../helpers/validation';
import RedirectToLogin from '../../../account/RedirectToLogin';
import { FormPaper, FormTextField, FormHeader, FormStyled, InputBlockWrapper } from '../../GuestSite.styles';
import { Title3 } from '../../landingPage/LandingPage.styles';
import { useRestorePasswordFormController } from './RestorePasswordForm.controller';
import Logo from '../../../sharedComponents/logo/Logo';

const RestorePassword = () => {
  const [formState, setFormState] = useState('');
  const [emailValidationError, setEmailValidationError] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [restore, { loading }] = useUserPasswordResetRequestMutation({
    fetchPolicy: 'network-only'
  });
  const { formText } = useRestorePasswordFormController();

  const onFinish = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (Validate.email.check(formState)) {
      const result = await restore({
        variables: {
          email: formState
        }
      });
      const resData = result.data;
      if (resData) {
        enqueueSnackbar(resData?.userPasswordResetRequest, { variant: 'success' });
      }
    } else {
      setEmailValidationError(Validate.email.error);
    }
  };

  return (
    <FormPaper>
      <FormHeader>
        <Logo isLogoOnly={true} />
        <Title3>Restore password</Title3>
      </FormHeader>
      <FormStyled onSubmit={onFinish}>
        <InputBlockWrapper>
          <InputLabel>{formText.restorePass.emailLabel}</InputLabel>
          <FormTextField
            id="email"
            size="small"
            placeholder="karl@gmail.com"
            fullWidth={true}
            autoFocus={true}
            value={formState}
            onChange={e => {
              setEmailValidationError('');
              setFormState(e.target.value);
            }}
            helperText={emailValidationError}
            error={!!emailValidationError}
          />
        </InputBlockWrapper>
        <Button
          type="submit"
          variant="contained"
          startIcon={loading && <CircularProgress size={18} />}
          disabled={!!emailValidationError || loading}
          size="large"
          fullWidth={true}
        >
          Send
        </Button>
      </FormStyled>

      <RedirectToLogin />
    </FormPaper>
  );
};

export default RestorePassword;
