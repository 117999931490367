import React from 'react';
import { Title2, TypographyStyled } from '../../landingPage/LandingPage.styles';
import { Typography } from '@mui/material';
import { LoginForm } from '../../forms/loginForm/LoginForm';
import { CtaRoot, CtaTextWrapper } from '../../GuestSite.styles';

const LoginCta: React.FC = () => {
  const ctaTitle = 'Welcome back!';
  const ctaSubtitle = 'Welcome back! Please enter your details.';
  return (
    <CtaRoot>
      <CtaTextWrapper>
        <Title2>{ctaTitle}</Title2>
        <Typography variant="h4" fontWeight={400} color={'text.secondary'}>
          {ctaSubtitle}
        </Typography>
      </CtaTextWrapper>
      <LoginForm />
    </CtaRoot>
  );
};

export default LoginCta;
