import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert, Button, CircularProgress, IconButton, InputAdornment, InputLabel } from '@mui/material';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../../../helpers/constants';
import { CenteredDivStyled } from '../../../account/Account.styles';
import { useLoginFormController } from './LoginForm.controller';
import {
  ForgotPasswordRowStyled,
  FormStyled,
  FormTextField,
  LinkStyled,
  UserLoginErrorStyled
} from '../../GuestSite.styles';
import Logo from '../../../sharedComponents/logo/Logo';
import { Title3 } from '../../landingPage/LandingPage.styles';
import { FormHeader, FormPaper, InputBlockWrapper } from '../../GuestSite.styles';
export const LoginForm: FC = () => {
  const {
    formErrors,
    handleClickShowPassword,
    loading,
    onLoginFormSubmitHandle,
    register,
    showAPIError,
    showPassword,
    userLoginError,
    formText
  } = useLoginFormController();

  const errorMessage = () => {
    let errorMessageText: string | undefined = 'An error occurred.';

    if (userLoginError) {
      const gqlError = userLoginError.graphQLErrors[0];
      if (gqlError.path?.join('.') === 'login') {
        errorMessageText = gqlError.message;
        if (errorMessageText?.includes('activate')) {
          return (
            <div>
              <div>{errorMessageText}</div>
              <Link to={AppRoutes.ResendActivationLink}>Or resend new activation link</Link>
            </div>
          );
        } else return errorMessageText;
      }
    }
    return errorMessageText;
  };

  return (
    <FormPaper>
      <FormHeader>
        <Logo isLogoOnly={true} />
        <Title3>{formText.login.title}</Title3>
      </FormHeader>
      <FormStyled onSubmit={onLoginFormSubmitHandle}>
        <InputBlockWrapper onSubmit={onLoginFormSubmitHandle}>
          <InputLabel>{formText.login.emailLabel}</InputLabel>
          <FormTextField
            id="email"
            helperText={formErrors.email?.message}
            error={!!formErrors.email}
            size="small"
            placeholder="karl@gmail.com"
            fullWidth
            {...register('email')}
          />
        </InputBlockWrapper>

        <InputBlockWrapper>
          <InputLabel>{formText.login.passwordLabel}</InputLabel>
          <FormTextField
            id="password"
            helperText={formErrors.password?.message}
            error={!!formErrors.password}
            size="small"
            placeholder="password"
            type={showPassword ? 'text' : 'password'}
            fullWidth={true}
            {...register('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </InputBlockWrapper>

        <ForgotPasswordRowStyled>
          <LinkStyled to={AppRoutes.PasswordReset}>{formText.login.bottomText1Cta}</LinkStyled>
        </ForgotPasswordRowStyled>

        <Button
          type="submit"
          variant="contained"
          startIcon={loading && <CircularProgress size={18} />}
          disabled={loading}
          fullWidth={true}
          size="large"
        >
          {formText.login.submitButton}
        </Button>
      </FormStyled>

      {!loading && showAPIError ? (
        <UserLoginErrorStyled>
          {userLoginError ? <Alert severity="error">{errorMessage()}</Alert> : null}
        </UserLoginErrorStyled>
      ) : null}

      <CenteredDivStyled>
        {formText.login.bottomText2} <LinkStyled to={'/signup'}>{formText.login.bottomText2Cta}</LinkStyled>
      </CenteredDivStyled>
    </FormPaper>
  );
};
