import { Stack, styled, Accordion, Typography } from '@mui/material';

export const Root = styled(Stack)`
  flex-direction: row;
  gap: 20px;
  width: 100%;
`;

export const AccordionStyled = styled(Accordion)`
  margin: 0;
  background-color: white;

  ::before {
    background-color: unset;
    border-bottom: 1px dashed ${({ theme }) => theme.palette.primary.light};
  }
`;

export const AccordionDetailsTextStyled = styled(Typography)`
  font-size: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 16px;
  }
`;

export const IconWrapper = styled('div')`
  padding: 10px;
  color: ${({ theme }) => theme.palette.primary.dark};
`;
