import { Stack, styled } from '@mui/material';

export const TopNavigation = styled(Stack)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 0 0 5rem;
  height: 76px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xs}px) {
    height: 52px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 0 0 0 1rem;
  }
`;

export const ButtonsBox = styled(Stack)`
  flex-direction: row;
  align-items: center;
`;
