import { Stack, styled, Typography } from '@mui/material';

export const LogoRoot = styled(Stack)`
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 0.25rem;
`;

export const LogoText = styled(Typography)`
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    display: none;
  }
`;
