import React, { FC } from 'react';
import { ButtonStyled, ButtonTextStyled } from './SignUpButton.styles';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from '../../../../../helpers/constants';
import { ReactComponent as Arrow } from '../../../../../assets/svg/upright-arrow.svg';

export const SignUpButton: FC = () => {
  const history = useHistory();

  return (
    <ButtonStyled onClick={() => history.push(AppRoutes.Signup)} variant="contained">
      <ButtonTextStyled>Sign up</ButtonTextStyled>
      <Arrow />
    </ButtonStyled>
  );
};
