import { Stack, styled, Typography } from '@mui/material';

export const Root = styled(Stack)`
  flex-basis: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  height: 494px;
  gap: 24px;
  padding: 40px 24px;

  background-color: white;
  border-radius: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    align-self: stretch;
    height: auto;
  }
`;

export const ColorBullet = styled(Typography)`
  font-size: 4rem;
  line-height: 0.5;
`;
