import { Button, styled, Typography } from '@mui/material';

export const ButtonStyled = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: unset;
  width: 232px;
  height: 76px;
  padding: 24px 16px 24px 16px;
  gap: 0.25rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xs}px) {
    width: 126px;
    height: 51px;
    padding: 12px 16px 12px 16px;
  }
`;

export const ButtonTextStyled = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xs}px) {
    font-size: 16px;
  }
`;
