import { useLocation } from 'react-router-dom';
import { AppRoutes } from 'src/helpers/constants';

export const useLandingPageHeaderController = () => {
  const location = useLocation();
  const locationPath = location.pathname;
  const isPathLogin = locationPath === AppRoutes.Login;
  const isPathSignup = locationPath === AppRoutes.Signup;

  return {
    isPathLogin,
    isPathSignup
  };
};
