import React from 'react';
import { Title2 } from '../../landingPage/LandingPage.styles';
import { Typography } from '@mui/material';
import RestorePasswordForm from '../../forms/restorePasswordForm/RestorePasswordForm';
import { CtaRoot, CtaTextWrapper } from '../../GuestSite.styles';

const RestorePasswordCta: React.FC = () => {
  const ctaTitle = 'Forgot your password? ';
  const ctaSubtitle = 'Enter your email and we’ll send you password reset\u00A0instructions';
  return (
    <CtaRoot>
      <CtaTextWrapper>
        <Title2>{ctaTitle}</Title2>
        <Typography variant="h4" fontWeight={400} color={'text.secondary'}>
          {ctaSubtitle}
        </Typography>
      </CtaTextWrapper>
      <RestorePasswordForm />
    </CtaRoot>
  );
};

export default RestorePasswordCta;
