import { Box, Paper, Stack, styled, Typography } from '@mui/material';
import { ReactComponent as AppScreenshot } from '../../../assets/svg/app-screenshot.svg';
import { ReactComponent as AboutBackground } from '../../../assets/svg/landing-about-bg.svg';
export const Root = styled(Paper)`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
`;

export const Hero = styled(Box)`
  padding: 96px 32px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 60px 16px;
  }
`;

export const HeroInnerContainer = styled(Stack)`
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

export const TextWrapper = styled(Stack)`
  justify-content: flex-start;
  justify-self: center;
  align-items: center;
  gap: 1.5rem;
  max-width: 768px;
`;

export const About = styled(Box)`
  padding: 48px 32px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 32px 16px 60px 16px;
  }
`;

export const AboutSection = styled(Stack)`
  padding: 20px 0;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const AboutBackgroundStyled = styled(AboutBackground)`
  color: ${({ theme }) => theme.palette.primary.dark};
  width: 100%;
  height: auto;
`;

export const AppScreenshotStyled = styled(AppScreenshot)`
  width: 70%;
  height: auto;
  position: absolute;
`;

export const Perks = styled(Stack)`
  padding: 0 32px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 0 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xs}px) {
    padding: 0;
  }
`;

export const PerksContainer = styled(Stack)`
  gap: 3rem;
  padding: 60px 32px;
  background: ${({ theme }) => theme.palette.primary.lighter};
  width: 100%;
`;

export const PerksHeadingWrapper = styled(Stack)`
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
`;

export const PerksCardsWrapper = styled(Stack)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Faq = styled(Stack)`
  flex-direction: row;
  padding: 96px 80px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 60px 16px;
  }
`;

export const FaqContainer = styled(Stack)`
  flex-direction: row;
  gap: 40px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FaqListWrapper = styled(Stack)`
  gap: 24px;
  flex-grow: 1;
  padding: 48px 0 0 0;
`;

export const Cta = styled(Stack)`
  flex-direction: row;
  padding: 96px 80px;
  width: 100%;
  gap: 32px;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    flex-wrap: wrap;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 96px 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xs}px) {
    padding: 60px 16px;
  }
`;

export const Title1 = styled(Typography)`
  max-width: 1018px;
  width: 100vw;
  font-family: 'Orbitron', sans-serif;
  font-size: 72px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 48px;
  }
`;

export const Title2 = styled(Typography)`
  font-family: 'Orbitron', serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 36px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xs}px) {
    font-size: 28px;
`;

export const Title3 = styled(Typography)`
  font-family: 'Orbitron', serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    font-size: 24px;
  }
`;

export const TypographyStyled = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0%;
  text-align: left;
  width: 480px;
`;

export const Bullet = styled(Typography)`
  dfont-family: 'Inter';
  color: ${({ theme }) => theme.palette.primary.dark};
`;

export const Body1 = styled(Typography)`
  font-family: 'Inter', serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0%;
  color: ${({ theme }) => theme.palette.text.secondary};
`;
