import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  1000: '#020607FF',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

//==============LIGHT THEME=================

//-----Let's keep this commented styles for a while----
// const PRIMARY = {
//   lighter: '#E8F3FD',
//   light: '#89C2D9',
//   main: '#61A5C2',
//   dark: '#468FAF',
//   darker: '#2C7DA0',
//   contrastText: '#fff'
// };

const PRIMARY = {
  lighter: '#F5F8FF',
  light: '#A4BCFD',
  main: '#444CE7',
  dark: '#3538CD',
  darker: '#2D31A6',
  contrastText: '#fff'
};

const SECONDARY = {
  lighter: '#D6E7DD',
  light: '#88D4AB',
  main: '#56AB91',
  dark: '#469D89',
  darker: '#358F80',
  contrastText: '#fff'
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff'
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800]
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800]
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff'
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4']
};

const light = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  background: { paper: '#F7F7F7', default: '#E6E9EF', neutral: '#E0E0E0' },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  },
  mode: 'light'
};

//==============DARK THEME=================
const PRIMARY_DARK = {
  lighter: '#394C5A',
  light: '#4F6270',
  main: '#657A87',
  dark: '#7B929E',
  darker: '#91AAB5',
  contrastText: '#E0E0E0'
};

const SECONDARY_DARK = {
  lighter: '#294E52',
  light: '#3E6669',
  main: '#53807F',
  dark: '#689995',
  darker: '#7DB1AB',
  contrastText: '#E0E0E0'
};

const INFO_DARK = {
  lighter: '#242F40',
  light: '#394956',
  main: '#4F626D',
  dark: '#657A83',
  darker: '#7B929A',
  contrastText: '#E0E0E0'
};

const SUCCESS_DARK = {
  lighter: '#1B4B1B',
  light: '#2D6F2D',
  main: '#3F933F',
  dark: '#51B751',
  darker: '#63DB63',
  contrastText: '#E0E0E0'
};

const WARNING_DARK = {
  lighter: '#7C6A2D',
  light: '#9D8A3D',
  main: '#BEA94D',
  dark: '#DFC85D',
  darker: '#FFEA6D',
  contrastText: '#1A1A1A'
};

const ERROR_DARK = {
  lighter: '#6D3B3B',
  light: '#8D4F4F',
  main: '#AE6363',
  dark: '#CE7777',
  darker: '#EE8B8B',
  contrastText: '#E0E0E0'
};

const CHART_COLORS_DARK = {
  violet: ['#574191', '#7360AB', '#8D7FC5', '#A79EDF'],
  blue: ['#1B4965', '#31678E', '#4682B4', '#5A9BD4'],
  green: ['#20603D', '#2D856E', '#3AA789', '#46C8A2'],
  yellow: ['#665D1E', '#998B2D', '#CCB93C', '#FFEB4D'],
  red: ['#571B1B', '#7D2C2C', '#A23D3D', '#C74E4E']
};

const dark = {
  primary: { ...PRIMARY_DARK },
  secondary: { ...SECONDARY_DARK },
  chart: CHART_COLORS_DARK,
  info: { ...INFO_DARK },
  grey: GREY,
  gradients: GRADIENTS,
  success: { ...SUCCESS_DARK },
  warning: { ...WARNING_DARK },
  error: { ...ERROR_DARK },
  background: { paper: '#282C34', default: '#202020', neutral: '#33373E' },
  text: { primary: '#E0E0E0', secondary: '#B0B0B0', disabled: '#808080' },
  mode: 'dark'
};

export default { light, dark };
