import React from 'react';
import LandingPageHeader from './components/LandingPageHeader/LandingPageHeader';
import LandingPageFooter from './components/landingPageFooter/LandingPageFooter';
import { GuestSiteLayoutRoot } from './GuestSite.styles';

const GuestSiteLayout: React.FC = ({ children }) => {
  return (
    <GuestSiteLayoutRoot>
      <LandingPageHeader />
      {children}
      <LandingPageFooter />
    </GuestSiteLayoutRoot>
  );
};

export default GuestSiteLayout;
