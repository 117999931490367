import React, { FC } from 'react';
import { ColorBullet, Root } from './Card.styles';
import { Body1, Title3 } from '../../LandingPage.styles';

interface CardProps {
  title: string;
  text: string;
  themeColor: string;
}
export const Card: FC<CardProps> = ({ title, text, themeColor }) => {
  return (
    <Root>
      <ColorBullet color={themeColor} fontSize={48}>
        ■
      </ColorBullet>
      <Title3>{title}</Title3>
      <Body1>{text}</Body1>
    </Root>
  );
};
