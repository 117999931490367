import React from 'react';
import Logo from '../../../sharedComponents/logo/Logo';
import { Typography } from '@mui/material';
import { FooterRoot, FooterWrapper } from './LandingPageFooter.styles';

const LandingPageFooter: React.FC = () => {
  return (
    <FooterRoot>
      <FooterWrapper>
        <Logo />
        <Typography variant="body1">&copy; 2024 SoCoders. All rights reserved.</Typography>
      </FooterWrapper>
    </FooterRoot>
  );
};

export default LandingPageFooter;
