export const useLandingPageController = () => {
  const text = {
    heroTitle: 'Your Tech Hub for learning, collaboration, and growth',
    heroSubtitle:
      'Create and join live workshops, connect with tech experts, and collaborate on projects—all in one place.',
    aboutBullet: '■ About us',
    aboutTitle: 'What is SoCoders?',
    aboutSubtitle:
      'We are a social platform built for the tech community to connect, learn, and grow. Whether you want to\n' +
      '              mentor, be mentored, collaborate, or share knowledge, Mentorium has everything you need.',
    perksBullet: '■ Learn. Connect. Collaborate.',
    perksTitle: 'Why us',
    faqBullet: '■ FAQ',
    faqTitle: 'Got questions?',
    ctaTitle: 'Ready to dive in?',
    ctaSubtitle:
      'Join SoCoders and start organizing workshops, collaborating with others, and growing your network\u00A0today.'
  };

  const cards = [
    {
      title: 'Host and Join Live Workshops',
      themeColor: '#528BFF',
      text: "Whether you're leading a 1-on-1 coaching session or hosting a group workshop, managing and participating in live, interactive learning experiences has never been easier. Share your expertise or learn something new—all in real-time."
    },
    {
      title: 'Connect with Fellow Tech Enthusiasts',
      themeColor: '#22CCEE',
      text: "Join a community where ideas flow freely. Participate in discussions, share your projects, and get feedback from peers who understand the tech landscape. It's more than just networking—it's about real connection and growth."
    },
    {
      title: 'Find Your Next Teammate or Opportunity',
      themeColor: '#2ED3B7',
      text: 'Looking for collaborators, new teammates, or your next big project? Socoders helps you discover like-minded people in tech, making it easy to build meaningful professional relationships and explore opportunities that align with your goals.'
    }
  ];

  return { cards, text };
};
