import React, { FC } from 'react';
import { AppBarStyled, IconButtonStyled, LogoStyled, ToolbarStyled } from './Header.styles';
import GlobalSearchInput from '../globalSearch/SearchField/GlobalSearchInput';
import logo from '../../assets/logo.png';
import { Brightness6Outlined, Menu as MenuIcon } from '@material-ui/icons';
import { IconButton } from '@mui/material';
import { useThemeColorModeContext } from '../../providers/ThemeColorModeProvider';
import { NotificationToggle } from '../notification/notificationsToggle/notificationToggle';
import { AppRoutes } from '../../helpers/constants';

interface HeaderProps {
  toggleMenu: () => void;
}

const Header: FC<HeaderProps> = ({ toggleMenu }) => {
  const { colorModeToggle } = useThemeColorModeContext();

  return (
    <AppBarStyled>
      <ToolbarStyled>
        <LogoStyled to={AppRoutes.Publications}>
          <img src={logo} alt="SoCoders" />
        </LogoStyled>
        <IconButtonStyled id="nav-bar-toggle" color="inherit" onClick={toggleMenu}>
          <MenuIcon />
        </IconButtonStyled>
        <GlobalSearchInput />
        <NotificationToggle />
        <IconButton id="dark-mode-toggle" color="inherit" onClick={colorModeToggle}>
          <Brightness6Outlined />
        </IconButton>
      </ToolbarStyled>
    </AppBarStyled>
  );
};

export default Header;
