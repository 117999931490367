import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useProfile } from '../helpers/customHooks';
import GuestSiteLayout from './guestSite/GuestSiteLayout';
import { AppRoutes } from '../helpers/constants';

const GuestRoute = ({ component: Component, ...rest }: any) => {
  const { isActivated } = useProfile();

  return (
    <Route
      {...rest}
      render={props =>
        isActivated ? (
          <Redirect to={AppRoutes.Publications} />
        ) : (
          <GuestSiteLayout>
            <Component {...props} />
          </GuestSiteLayout>
        )
      }
    />
  );
};

export default GuestRoute;
