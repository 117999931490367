import { Alert, Paper, styled, TextField, Typography, Container } from '@mui/material';

export const PaperStyled = styled(Paper)`
  min-width: 512px;
  margin-left: auto;
  padding: 32px 40px;
  background-color: rgb(245, 248, 255);
  border: 1px solid rgb(164, 188, 253);
  gap: 24px;
  position: relative;
  display: flex;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin: auto;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    width: 90vw;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xs}px) {
    min-width: unset;
  }
`;

export const TextFieldStyled = styled(TextField)`
  margin-bottom: 1.25rem;
`;

export const CenteredDivStyled = styled('div')`
  text-align: center;
`;

export const AlertStyled = styled(Alert)`
  margin-bottom: 1rem;
`;
