import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Alert, Box, Button, CircularProgress, IconButton, InputAdornment, InputLabel } from '@mui/material';
import React, { FC } from 'react';
import { Validate } from '../../../../helpers/validation';
import { CenteredDivStyled } from '../../../account/Account.styles';
import PasswordValidaitonRender from '../../../account/passwordValidation/PasswordValidationRender';
import RedirectToLogin from '../../../account/RedirectToLogin';
import { useSignUpFormController } from './SignUpForm.controller';
import { Title3 } from '../../landingPage/LandingPage.styles';
import {
  FormBottomText,
  FormHeader,
  FormPaper,
  FormStyled,
  FormTextField,
  InputBlockWrapper,
  PaperRegistrationSuccessStyled
} from '../../GuestSite.styles';
import Logo from '../../../sharedComponents/logo/Logo';

export const SignUpForm: FC = () => {
  const {
    data,
    onFinish,
    formValidationError,
    setFormValidationError,
    setFormState,
    formState,
    showPassword,
    handleClickShowPassword,
    loading,
    error,
    listOfChecks,
    strength,
    formText
  } = useSignUpFormController();

  if (data) {
    return (
      <>
        <PaperRegistrationSuccessStyled>
          <Box>
            <CenteredDivStyled>
              <div>{formText.signUpSuccess.title}</div>
              <div>{formText.signUpSuccess.subtitle}</div>
            </CenteredDivStyled>
            <RedirectToLogin />
          </Box>
        </PaperRegistrationSuccessStyled>
      </>
    );
  }

  return (
    <FormPaper>
      <FormHeader>
        <Logo isLogoOnly={true} />
        <Title3>{formText.signUp.title}</Title3>
      </FormHeader>
      <FormStyled onSubmit={onFinish}>
        <InputBlockWrapper>
          <InputLabel>{formText.signUp.emailLabel}</InputLabel>
          <FormTextField
            id="email"
            required={true}
            size="small"
            placeholder="karl@gmail.com"
            fullWidth={true}
            onChange={e => {
              setFormValidationError({ ...formValidationError, email: '' });
              setFormState({ ...formState, email: e.target.value });
            }}
            onBlur={e => {
              if (!Validate.email.check(e.target.value))
                setFormValidationError({ ...formValidationError, email: Validate.email.error });
            }}
            helperText={formValidationError.email}
            error={!!formValidationError.email}
          />
        </InputBlockWrapper>

        <InputBlockWrapper>
          <InputLabel>{formText.signUp.passwordLabel}</InputLabel>
          <FormTextField
            id="password"
            required={true}
            size="small"
            placeholder="password"
            type={showPassword ? 'text' : 'password'}
            fullWidth={true}
            onChange={e => {
              setFormValidationError({ ...formValidationError, password: '' });
              setFormState({ ...formState, password: e.target.value });
            }}
            onBlur={e => {
              if (!Validate.password.check(e.target.value))
                setFormValidationError({ ...formValidationError, password: Validate.password.error });
            }}
            helperText={formValidationError.password}
            error={!!formValidationError.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </InputBlockWrapper>

        {formState.password && (
          <PasswordValidaitonRender listOfChecks={listOfChecks} percentageOfChecksPassed={strength} />
        )}

        <Button
          size="large"
          type="submit"
          fullWidth={true}
          variant="contained"
          startIcon={loading && <CircularProgress size={18} />}
          disabled={!!formValidationError.email || !!formValidationError.password || loading}
        >
          {formText.signUp.submitButton}
        </Button>
      </FormStyled>

      {error && <Alert severity="error">{error?.message.split(': ')[1]}</Alert>}

      <FormBottomText>
        {formText.signUp.bottomText}
        <RedirectToLogin inline prefix="" />
      </FormBottomText>
    </FormPaper>
  );
};

export default SignUpForm;
