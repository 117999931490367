import { Paper, Stack, styled, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import { ReactComponent as CtaArrow } from '../../assets/svg/cta-arrow.svg';

export const GuestSiteLayoutRoot = styled(Stack)`
  min-height: 100vh;
`;

export const ButtonLink = styled(Link)`
  ${({ theme }) => theme.typography.subtitle1};
  color: initial;

  :hover {
    text-decoration: unset;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

interface LoginButtonLinkProps {
  hideSignUp?: boolean;
  hideLogin?: boolean;
}

export const LoginButtonLink = styled(Link)<LoginButtonLinkProps>`
  width: ${({ hideSignUp, hideLogin }) => (hideSignUp && !hideLogin ? '232px' : 'auto')};
  height: ${({ hideSignUp, hideLogin }) => (hideSignUp && !hideLogin ? '75px' : 'auto')};
  padding: ${({ hideSignUp, hideLogin }) => (hideSignUp && !hideLogin ? '0px 32px' : '0px 40px 0px 0px')};
  gap: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: initial;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xs}px) {
    width: 126px;
    height: 52px;
    padding: 12px 16px 12px 16px;
  }
`;

//CTA
export const CtaRoot = styled(Stack)`
  flex-direction: row;
  padding: 96px 80px;
  width: 100%;
  gap: 32px;
  align-items: center;
  max-width: 96rem;
  justify-content: space-between;
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    flex-wrap: wrap;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding: 96px 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xs}px) {
    padding: 60px 16px;
  }
`;

export const CtaTextWrapper = styled(Stack)`
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.5rem;
  max-width: 768px;
`;

export const CtaArrowStyled = styled(CtaArrow)`
  position: static;
  color: ${props => props.theme.palette.primary.main};
`;

//FORM STYLES
export const FormPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  min-width: 512px;
  padding: 32px 40px;
  background-color: ${props => props.theme.palette.primary.lighter};
  border: 1px solid ${props => props.theme.palette.primary.light};
  gap: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    margin: auto;
    width: 90vw;
    min-width: unset;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.xs}px) {
    min-width: unset;
  }
`;

export const FormHeader = styled(Stack)`
  align-items: center;
  gap: 16px;
`;

export const FormStyled = styled('form')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InputBlockWrapper = styled(Stack)``;

export const FormTextField = styled(TextField)`
  input {
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    background-color: white;
  }
`;

export const FormBottomText = styled('div')`
  align-self: center;

  a {
    color: ${({ theme }) => theme.palette.primary.main};

    :hover {
      text-decoration: unset;
      color: ${({ theme }) => theme.palette.primary.light};
    }
  }
`;

export const PaperRegistrationSuccessStyled = styled(Paper)`
  margin: 0 auto;
  background-color: ${props => props.theme.palette.success.light};
  padding: 20px;
  width: fit-content;
`;

export const UserLoginErrorStyled = styled('div')`
  margin-top: 1rem;
`;

export const ForgotPasswordRowStyled = styled('div')`
  text-align: right;
`;

export const LinkStyled = styled(Link)`
  color: ${props => props.theme.palette.primary.main};
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0;
  margin-bottom: 8px;
`;
