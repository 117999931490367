import React, { FC } from 'react';
import {
  About,
  AboutBackgroundStyled,
  AboutSection,
  AppScreenshotStyled,
  Body1,
  Bullet,
  Faq,
  FaqContainer,
  FaqListWrapper,
  Hero,
  HeroInnerContainer,
  Perks,
  PerksCardsWrapper,
  PerksContainer,
  PerksHeadingWrapper,
  Root,
  TextWrapper,
  Title1,
  Title2
} from './LandingPage.styles';
import { SignUpButton } from './components/signUpButton/SignUpButton';
import { Card } from './components/card/Card';
import { FaqList } from './components/faqList/FaqList';
import { useLandingPageController } from './LandingPage.controller';
import SignupCta from '../components/singupCta/SignupCta';

export const LandingPage: FC = () => {
  const { cards, text } = useLandingPageController();

  return (
    <>
      <Root>
        <Hero>
          <HeroInnerContainer>
            <TextWrapper>
              <Title1 textAlign="center">{text.heroTitle}</Title1>
              <Body1 textAlign="center" alignSelf="center">
                {text.heroSubtitle}
              </Body1>
            </TextWrapper>
            <SignUpButton />
          </HeroInnerContainer>
        </Hero>

        <About>
          <TextWrapper>
            <TextWrapper>
              <Bullet variant="subtitle1" textAlign="center">
                {text.aboutBullet}
              </Bullet>
              <Title2 textAlign="center">{text.aboutTitle}</Title2>
            </TextWrapper>
            <Body1 textAlign="center">{text.aboutSubtitle}</Body1>
          </TextWrapper>
          <AboutSection>
            <AboutBackgroundStyled />
            <AppScreenshotStyled />
          </AboutSection>
        </About>

        <Perks>
          <PerksContainer>
            <PerksHeadingWrapper>
              <Bullet variant="subtitle1" textAlign="center">
                {text.aboutBullet}
              </Bullet>
              <Title2>{text.perksTitle}</Title2>
            </PerksHeadingWrapper>
            <PerksCardsWrapper>
              {cards.map(({ title, text, themeColor }, index) => (
                <Card key={index} title={title} text={text} themeColor={themeColor} />
              ))}
            </PerksCardsWrapper>
          </PerksContainer>
        </Perks>

        <Faq>
          <FaqContainer>
            <PerksHeadingWrapper>
              <Bullet variant="subtitle1">{text.faqBullet}</Bullet>
              <Title2>{text.faqTitle}</Title2>
            </PerksHeadingWrapper>
            <FaqListWrapper>
              <FaqList />
            </FaqListWrapper>
          </FaqContainer>
        </Faq>

        <SignupCta />
      </Root>
    </>
  );
};
